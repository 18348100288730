import {AfterViewInit, Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {GameService} from "../../../swagger/api/game.service";
import {GeneralService} from "../../../general.service";
import {ImageService} from "../../../services/image.service";
import {HttpHeaders} from "@angular/common/http";
import {MatDialog} from '@angular/material/dialog';
import {GameRelease} from "../../../swagger/model/gameRelease";
import {FormControl} from "@angular/forms";
import {MatSelectionList} from "@angular/material/list";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {RouteParamGetterService} from "../../../services/route-param-getter.service";
import {OrganizationService} from "../../../swagger/api/organization.service";
import {Game} from "../../../swagger/model/game";
import {interval, Subscription} from 'rxjs';
import StateEnum = GameRelease.StateEnum;

@Component({
  selector: 'app-build',
  templateUrl: './build.component.html',
  styleUrls: ['./build.component.scss']
})
export class BuildComponent implements OnInit, AfterViewInit, OnDestroy {

  public showWhat: number = 0;
  public idPath: number | undefined = undefined;
  public gameId: number;
  public idOrganisation: number;
  public game: Game = {};
  @ViewChild('gameReleasesSelect', {static: false}) gameReleasesSelect: MatSelectionList;
  hide = true;

  public gameReleases: GameRelease[] = [];
  public uploadProgress = 0;
  public isLoading = false;
  major = new FormControl(0);
  minor = new FormControl(0);
  patch = new FormControl(1);
  public gameReleasesSelected: GameRelease = {};
  private checkStateSubscription: Subscription | undefined;

  constructor(private gameService: GameService, private organizationService: OrganizationService, private imageService: ImageService,
              private route: ActivatedRoute) {

    this.gameId = +this.route.parent?.snapshot.paramMap.get('idGame')!;
    this.idOrganisation = +this.route.parent?.snapshot.paramMap.get('idOrganization')!;

  }

  ngAfterViewInit(): void {
    this.gameReleasesSelect.options.changes.subscribe((data) => {
      if (this.gameReleasesSelect.options.length > 1) {
        this.gameReleasesSelect.selectedOptions.select(this.gameReleasesSelect.options.get(1)!);
        this.changeView()
      }
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.organizationService.getGamesByIdForPartner(this.idOrganisation, this.gameId).subscribe((game) => {
      this.game = game;
    });
    this.refreshGameReleases();

  }

  ngOnDestroy(): void {
    this.stopCheckingGameReleasesState();
  }

  toggleVisibility(inputElement: HTMLInputElement): void {
    this.hide = !this.hide;

    // Attendre un petit délai pour que l'input soit actualisé puis sélectionner le texte
    setTimeout(() => {
      inputElement.select();
    }, 0);
  }

  refreshGameReleases() {
    this.gameService.getGameRelease(this.gameId).subscribe((gameReleases) => {
      //tri les games releases par numéro de version
      if (gameReleases && gameReleases.length > 0) {
        gameReleases.sort((a, b) => {
          if (a.major! > b.major!) {
            return -1;
          }
          if (a.major! < b.major!) {
            return 1;
          }
          if (a.minor! > b.minor!) {
            return -1;
          }
          if (a.minor! < b.minor!) {
            return 1;
          }
          if (a.patch! > b.patch!) {
            return -1;
          }
          if (a.patch! < b.patch!) {
            return 1;
          }
          return 0;
        });

        if (gameReleases.length > 0) {
          this.major.setValue(gameReleases[0].major!);
          this.minor.setValue(gameReleases[0].minor!);
          this.patch.setValue(gameReleases[0].patch! + 1);
          this.gameReleases = [...gameReleases];
          this.gameReleasesSelect.selectedOptions.select(this.gameReleasesSelect.options.last);
          this.changeView()
        } else {
          this.major.setValue(0);
          this.minor.setValue(0);
          this.patch.setValue(1);
        }
      } else {
        this.major.setValue(0);
        this.minor.setValue(0);
        this.patch.setValue(1);
        this.gameReleases = [];
        this.gameReleasesSelect.selectedOptions.select(this.gameReleasesSelect.options.first);
        this.changeView()
      }
    }).add(() => {
      this.isLoading = false;
    });
  }

  validateAndCorrect(control: FormControl, isMajor: boolean) {
    let value = control.value;
    if (!value) {
      value = 0;
    }
    if (value !== null) {
      // Assurez-vous que la valeur ne commence pas par 0
      if (value.toString().charAt(0) === '0' && value > 0) {
        value = value.toString().slice(1);
      }
      value = Number.parseInt(value.toString().replace("+", ''));
      // Limitez la valeur à 9999 si elle dépasse
      if (value > 9999) {
        value = 9999;
      }
      if (value < 0) {
        value = 0;
      }
      if (isMajor) {
        if (!this.gameReleases || this.gameReleases.length < 1) {
          if (value > 1) {
            value = 1;
          }
        } else if (this.gameReleases[0].major != undefined && value > this.gameReleases[0].major + 2) {
          value = this.gameReleases[0].major + 1;
        }
      }
      console.log(this.gameReleases)
      console.log(this.gameReleases[0])
      // Mettre à jour la valeur du champ avec la valeur corrigée
      control.setValue(value);
    }
  }

  changeView() {
    this.stopCheckingGameReleasesState();

    if (!this.gameReleasesSelect.selectedOptions.selected[0]?.value) {
      this.showWhat = 0;
      return;
    } else if (this.gameReleasesSelect.selectedOptions.selected[0]?.value?.showKey) {
      this.showWhat = 2;
      return;
    }

    this.gameReleasesSelected = this.gameReleasesSelect.selectedOptions.selected[0]?.value;
    this.showWhat = 1;

    if (this.gameReleasesSelected.state !== 'exploited' &&
      this.gameReleasesSelected.state !== 'inprogress' && this.gameReleasesSelected.state !== 'deleted' && this.gameReleasesSelected.state !== 'history') {
      this.startCheckingGameReleasesState();
    }

  }

  resetFileInput(fileInput: HTMLInputElement) {
    // Réinitialiser la valeur du champ de fichier
    fileInput.value = '';
    // Vous pouvez ajouter ici d'autres logiques si nécessaire
  }

  cancelRelease(release: GameRelease) {
    this.gameService.deleteGameRelease(this.gameId, release.id!).subscribe(() => {
      this.refreshGameReleases();
    })
  }

  goLive(release: GameRelease) {
    this.gameService.setReleaseTolive(this.gameId, release.id!, this?.idPath).subscribe(() => {
      this.refreshGameReleases();
      this.startCheckingGameReleasesState();

    })
  }

  requestUpload(event: any) {
    if (event.target.files) {
      const files: File[] = event.target.files;
      if (files.length === 1) {
        let version: GameRelease = {}
        version.major = this.major.value
        version.minor = this.minor.value
        version.patch = this.patch.value
        const headers = new HttpHeaders().set('Content-Type', 'application/zip');
        this.gameService.uploadGameRelease(version, "paris", this.gameId).subscribe((uploadObject) => {
          this.imageService.uploadImage(files[0], uploadObject.linkUpload!, headers).subscribe(
            (progress) => {
              this.uploadProgress = progress;
            },
            (error) => {

            },
            () => {
              this.uploadProgress = 0;
              this.refreshGameReleases();
            }
          );
        });
      }
    }
  }

  private startCheckingGameReleasesState() {
    this.checkStateSubscription = interval(5000).subscribe(() => {
      this.gameService.getGameRelease(this.gameId).subscribe((gameReleases) => {
        if (gameReleases && gameReleases.length > 0) {
          const ongoingRelease = gameReleases.find(release =>
            release.state === StateEnum.Waiting ||
            release.state === StateEnum.Uploading ||
            release.state === StateEnum.Inprogress
          );

          if (!ongoingRelease) {
            this.stopCheckingGameReleasesState();
            this.refreshGameReleases();

          }

        }
      });
    });
  }

  private stopCheckingGameReleasesState() {
    if (this.checkStateSubscription) {
      this.checkStateSubscription.unsubscribe();
      this.checkStateSubscription = undefined;
    }
  }

  readonly GameRelease = GameRelease;
}
