<mat-tab-group animationDuration="0ms" [color]="'warn'" mat-align-tabs="center" class="text-primary dashboard-size"
               [(selectedIndex)]="selectedIndex" (selectedIndexChange)="onTabChange($event)">
  <mat-tab *ngIf="this.organizationContextService.getMemberContext()!.role !== 'regular'" label="{{ 'general.general' | translate }}">
  </mat-tab>
  <mat-tab label="{{ 'general.membres' | translate }}">
  </mat-tab>
  <mat-tab *ngIf="this.organizationContextService.getMemberContext()!.role === 'owner'" label="{{ 'general.finances' | translate }}">
  </mat-tab>
</mat-tab-group>
<div class="dashboard-size">
<router-outlet></router-outlet>

</div>
