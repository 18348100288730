<div class="menuMobile" fxLayout="row" style="position: sticky; z-index: 999999; top: 0"
     *ngIf="isMobile && !generalService.getIsinclient()">
  <button id="burger" (click)="toggleMenu()">
    <mat-icon>menu</mat-icon>
  </button>
  <a id="logoMobile" routerLink="/" fxLayout="row" fxLayoutAlign="start center">
    <div [style.color]="indieColor">Indie</div>
    <div [style.color]="passColor">Pass</div>
  </a>
</div>

<div *ngIf="!generalService.getIsinclient() " [class.open]="menuOpen" id="menu" fxLayoutAlign="start center"
     class=" mat-elevation-z6">
  <div id="nav-items" fxLayout="row">
    <a *ngIf="!isMobile" routerLink="/" id="logo" fxLayout="row" fxLayoutAlign="start center">
      <div [style.color]="indieColor">Indie</div>
      <div [style.color]="passColor">Pass</div>
    </a>
    <!-- <button (click)="menuOpen=!menuOpen" *ngIf="!presentation" mat-raised-button routerLink="/" id="accueil"
             color="primary">{{ 'ui.btn.accueil' | translate }}
     </button> -->
    <mat-nav-list class="nav-items">

      <mat-divider [vertical]="true"></mat-divider>
      <a *ngIf="!presentation" mat-list-item routerLink="/list-game" routerLinkActive="is-active">
        <span mat-line>{{ 'menu.listeDesJeux' | translate }}</span>
      </a>

      <!-- Lien pour devenir partenaire -->
      <a mat-list-item routerLink="/become-partner"
         routerLinkActive="is-active">
        <span mat-line>{{ 'menu.publierMonJeu' | translate }}</span>
      </a>

      <!-- Lien vers le tableau de bord, visible sous certaines conditions -->
      <a
        *ngIf=" (this.generalService?.getUserInfos()?.organizations && this.generalService?.getUserInfos()!.organizations!.length > 0) && !presentation"
        mat-list-item class="dashboard"
        [routerLink]="'/dashboard/' + this.generalService?.getOrganizationId() + '/games'"
        routerLinkActive="is-active">
        <span mat-line>Dashboard</span>
      </a>

      <!-- Zone de recherche, visible uniquement sur les non mobiles -->
      <div *ngIf="!isMobile && !presentation" class="search-bar-div">
        <mat-form-field appearance="legacy" class="custom-placeholder">
          <div matPrefix fxLayout="row" class="search-icon">
            <mat-icon>search</mat-icon>
            <label class="place-holder-search" *ngIf="!valueSearch">{{ 'search.RechercherUnJeu' | translate }}</label>
          </div>
          <input [matAutocomplete]="auto"
                 [(ngModel)]="valueSearch"
                 (ngModelChange)="research($event)"
                 matInput
                 class="searchbar">

          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let game of resultSearch" [value]="game.title" [routerLink]="'game/' + game.id"
                        (click)="navigateToGame(game)">
              <img alt="" class="img-research"
                   [src]="game?.gamePage?.graphics?.icon?.fileName! | buildImageUrl : game?.id!">
              <span>{{ game.title }}</span>
            </mat-option>
            <mat-option *ngIf=" showNoResult"><span>{{ 'search.AucunJeuTrouve' | translate }}</span>
            </mat-option>
            <mat-option *ngIf=" searching"><span>{{ 'search.Recherche...' | translate }}</span>
            </mat-option>

          </mat-autocomplete>
          <button class="clear-icon" *ngIf="valueSearch" matSuffix mat-icon-button aria-label="Clear"
                  (click)="valueSearch=''; resultSearch =[]">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="nav-items" fxLayoutAlign="end center">
        <!-- Utilisation de la balise <a> pour le lien "À propos" -->
        <a mat-list-item routerLink="/about">
          <span mat-line>{{ 'general.aPropos' | translate }}</span>
        </a>

        <!-- Utilisation de la balise <a> pour le lien "Installer", visible seulement sur non mobile -->
        <a *ngIf="!isMobile && !presentation" class="btn-install"
           [href]="environment.urlClientDDL" mat-list-item>
          <span mat-line>{{ 'general.installer' | translate }}</span>
        </a>

        <!-- Bouton pour connexion, transformé en lien -->

        <div *ngIf="this.generalService.getUserInfos()"
             class="login">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>account_circle</mat-icon>
            <div *ngIf="generalService.getIsConnected()" class="status-indicator"></div>
          </button>
          <mat-menu #menu="matMenu">
            <a mat-menu-item [routerLink]="'/account/settings'">
              <mat-icon>person</mat-icon>
              <span>{{ 'profil.monCompte' | translate }}</span>
            </a>
            <a *ngIf="!presentation" mat-menu-item [routerLink]="'/library'">
              <mat-icon>videogame_asset</mat-icon>
              <span>{{ 'general.maLibrairie' | translate }}</span>
            </a>
            <button (click)="logout()" mat-menu-item>
              <mat-icon>logout</mat-icon>
              <span>{{ 'general.seDeconnecter' | translate }}</span>
            </button>
          </mat-menu>
        </div>
        <button class="button-more-deco" *ngIf="!this.generalService.getUserInfos()" mat-icon-button [matMenuTriggerFor]="menuLangue">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menuLangue="matMenu" class="white-theme">
          <mat-form-field class="select-langue">
            <mat-label> {{ 'general.language' | translate }}</mat-label>
            <mat-select [value]="this.translate.currentLang" (valueChange)="useLanguage($event)">
              <mat-option value="en">English</mat-option>
              <mat-option value="fr">Français</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-menu>
        <a *ngIf="!this.generalService.getUserInfos()"
           class="login"
           routerLink="/login"
           mat-icon-button>
          <mat-icon>account_circle</mat-icon>
          <div *ngIf="generalService.getIsConnected()" class="status-indicator"></div>
        </a>

        <!-- Lien pour les paramètres du compte, visible si l'utilisateur est connecté -->
        <a *ngIf=" false && this.generalService.getUserInfos()" class="login"
           [routerLink]="'/account/settings'"
           mat-icon-button>
          <mat-icon>person</mat-icon>
          <div *ngIf="generalService.getIsConnected()" class="status-indicator"></div>
          <!-- {{ 'ui.btn.monCompte' | translate }} -->
        </a>
        <app-feedback *ngIf="isMobile && menuOpen && generalService.getIsConnected()" class="feedback"></app-feedback>

      </div>
    </mat-nav-list>


    <!-- <button *ngIf="!this.generalService.getUserInfos() && !isMobile && !presentation" class="options"
           mat-icon-button>
     <mat-icon>more_vert</mat-icon>
   </button> -->
  </div>

</div>

<div *ngIf="isMobile && menuOpen" class="blur-backdround" (click)="menuOpen = !menuOpen">

</div>
<div id="content">
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>

<app-cookie-consent *ngIf="!generalService.getIsinclient() " class="cookie-consent"></app-cookie-consent>
<app-feedback *ngIf="!isMobile && generalService.getIsConnected() && !generalService.getIsinclient() "
              class="feedback"></app-feedback>


