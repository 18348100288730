<div class="top">
  <img class="back-ground" src="assets/images/home.webp">
  <div class="text">
    <h1>
      {{ 'general.developpeurs' | translate }}<br>
    </h1>
    <h2> {{ 'register.decouvrezPourquoiRejoindre' | translate }}</h2>
  </div>

</div>

<div class="main-wrapper white-theme " *ngIf="!presentation">
  <div class="ip-content">

    <div class="ip-area" *ngIf="etat==='NULL'">
      <!--<div class="ip-area">-->
      <p class="justify pre-line">{{ 'register.rejoindreIndiepassPartenaire' | translate }}<b>Stripe</b>.
        <mat-icon [matTooltipClass]="'pre-line'" [matTooltipPosition]="'right'"
                  [matTooltip]="'helper.stripe' | translate">
          help
        </mat-icon>
      </p>
      <br>
      <!--mat-form-field>
        <mat-label>{{ 'general.votrePays' | translate }}</mat-label>
        <mat-select [disabled]="isCallingInscription" [(ngModel)]="country">
          <mat-option *ngFor="let country of countries" [value]="country.code">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field -->
      <br>
      <form class="example-form" [formGroup]="selectCountryForm">

        <mat-form-field class="example-full-width input-field" appearance="fill">
          <mat-label>{{ 'register.selectionnezVotrePays' | translate }}</mat-label>
          <input type="text"
                 placeholder="{{ 'register.selectionnezVotrePays' | translate }}"
                 matInput
                 (input)="checkCountry()"
                 (change)="checkCountry()"
                 (blur)="checkCountry()"
                 formControlName="countryCode"
                 [matAutocomplete]="auto">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nameEn">
              {{ option.nameEn }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
      <br>
      <button [disabled]="!selectCountryForm.valid" [appDisableButton]="isCallingInscription" mat-flat-button
              (click)="inscriptionStripe()" mat-raised-button
              color="primary">{{ 'register.minscrireSurStripe' | translate }}
      </button>
    </div>

    <div *ngIf="etat==='INCOMPLETE'">
      <div class="ip-area">
        <p>{{ 'register.inscriptionStripeIncomplete' | translate }}.</p>
        <br>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
          <button (click)="inscriptionStripe()" [appDisableButton]="isCallingInscription" mat-flat-button
                  color="primary">{{ 'register.terminerMonInscription' | translate }}
          </button>
          <button (click)="cancelInscriptionStripe()" [appDisableButton]="isCallingInscription" mat-raised-button
                  color="warn">{{ 'register.annulerMonInscription' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="ip-content">
      <div class="ip-area" *ngIf="etat==='CREATED'">
        <h3>
          {{ 'general.felicitations' | translate }} !
        </h3>
        <p>
          {{ 'register.inscriptionStripeTerminee' | translate }}

        </p>
        <form [formGroup]="developpeurFormulaire">
          <mat-form-field class="full-width  min-width-field" appearance="fill">
            <mat-label> {{ 'register.nomDeVotreOrganisation' | translate }}</mat-label>
            <input [minlength]="1" [maxlength]="40" formControlName="organisationName" matInput>
          </mat-form-field>

          <br>
          <mat-form-field class="full-width  min-width-field" appearance="fill">
            <mat-label>{{ 'register.emailDeContactDeVotreOrganisation' | translate }}</mat-label>
            <input [minlength]="1" [maxlength]="255" formControlName="email" matInput>
          </mat-form-field>
        </form>
        <button [disabled]="!complete()" [appDisableButton]="isCallingInscription" (click)="inscriptionDev()"
                mat-raised-button color="primary"> {{ 'general.valider' | translate }}
        </button>

      </div>
    </div>
    <div *ngIf="etat==='ATTENTE'">

    </div>
  </div>
</div>

<div fxLayout="column" fxLayoutAlign="center center" class="presentation">
  <div class="content pre-line">
    <h1 >{{ "partenaire.t1" | translate }}</h1>
    <p [innerHTML]="'partenaire.m1'| translate ">
    </p>
    <h1>{{ "partenaire.t2" | translate }}</h1>
    <p>{{ "partenaire.m2" | translate }} </p>
    <h1>{{ "partenaire.t3" | translate }}</h1>
    <p class="partenaire-m3" [innerHTML]="'partenaire.m3'| translate "></p>

  </div>
</div>


