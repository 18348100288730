import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {AuthService} from "../swagger/api/auth.service";
import {Router} from "@angular/router";
import {GeneralService} from "../general.service";
import {CookieService} from "../cookie.service";
import {IndiepassCodesEnum} from "../../model/enums/indiepass-codes-enum";
import {ActionsEnum} from "../snack-bar/enum-actions";
import {PannelsClassEnum} from "../snack-bar/pannels-class-enum";
import {SnackBarService} from "../services/snack-bar.service";


@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss']
})
export class ConnexionComponent implements OnInit {

  @Input()
  emaillForce?: string = undefined;
  public loginRoute: boolean = this.isLoginRoute();

  public loading = false;
  public sendingEmail = false;

  constructor(private snackBarService: SnackBarService, private formBuilder: FormBuilder, private router: Router, private generalService: GeneralService,
              private authService: AuthService) {
  }

  hide = true;
  hide1 = true;
  email = new FormControl('', [Validators.email]);
  checkoutForm = this.formBuilder.group({
    email: '',
    password: ''
  });

  getErrorMessage() {
    /*  if (this.email.hasError('required')) {
        return 'You must enter a value';
      }

      return this.email.hasError('email') ? 'Not a valid email' : ''; */
  }

  ngOnInit(): void {
    this.checkoutForm.get("email")?.setValue(this.emaillForce);
  }

  onSubmit(): void {
    //this.connexionService.post(this.checkoutForm.value)
    this.loading = true;
    this.authService.login(this.checkoutForm.value).subscribe(response => {

      this.generalService.setIsConnected(true)
      if (!this?.emaillForce) {
        this.router.navigate(['/account/' + this.generalService.getUserInfos().id])
      }

    }).add(() => {
      this.loading = false;
    });
  }

  //check if the route is equal to /login
  isLoginRoute() {
    return this.router.url === '/login';
  }  //check if the route is equal to /login
  sendMailResetPassword() {
    this.sendingEmail = true;
    this.authService.askResetPassword(this.checkoutForm.get("email")?.value).subscribe(date => {
      this.snackBarService.openSnackBar(IndiepassCodesEnum.MAIL_SEND_OK, ActionsEnum.Ok, PannelsClassEnum.info)
    }, error => {
      this.snackBarService.openSnackBar(IndiepassCodesEnum.MAIL_SEND_KO, ActionsEnum.Ok, PannelsClassEnum.error)
    }).add(() => {
      this.sendingEmail = false;
    })

  }
}
