import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../environments/environment";
import {GeneralService} from "../../general.service";
import {PublisherAndDeveloper} from "../../swagger/model/publisherAndDeveloper";
import {OrganizationService} from "../../swagger/api/organization.service";
import {Organization} from "../../swagger/model/organization";
import {COUNTRIES, Country} from "../../../model/CountryCode";
import {map, Observable, startWith} from "rxjs";

@Component({
  selector: 'app-devenir-partenaire',
  templateUrl: './devenir-partenaire.component.html',
  styleUrls: ['./devenir-partenaire.component.scss']
})
export class DevenirPartenaireComponent implements OnInit {

  public isCallingInscription = false;
  countryCodeSelected: Country;
  filteredOptions: Observable<Country[]> | undefined;

  countries : Country[];
  constructor(private http: HttpClient,
              private organizationService: OrganizationService,
              public generalService: GeneralService) {

    this.countries = COUNTRIES.filter(country => country.isDevelopperCountry);
  }

  public etat: string = ""
  public country: string;
  developpeurFormulaire = new FormGroup({
    organisationName: new FormControl(null, [Validators.minLength(1), Validators.maxLength(40), Validators.required]),
    email: new FormControl(null, [Validators.minLength(1), Validators.maxLength(40), Validators.required, Validators.email])
  });
  selectCountryForm = new FormGroup({
    countryCode: new FormControl(null, [Validators.required])
  });

  ngOnInit(): void {
    if (this.generalService.getIsConnected()) {
      this.organizationService.checkStatus().subscribe(data => {
        this.etat = data
      });
    }
    this.filteredOptions = this.selectCountryForm.get("countryCode")?.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }
  private _filter(value: string): Country[] {
    const filterValue = value.toLowerCase();
    const result = this.countries.filter(option => option.nameEn.toLowerCase().includes(filterValue));
    return result;
  }
  inscriptionStripe() {
    this.isCallingInscription = true;
    if (this.countryCodeSelected.code) {
      this.organizationService.createStripe(this.countryCodeSelected.code).subscribe(data => {
        window.location.href = data
      }, error => {
        this.isCallingInscription = false;
      });
    } else {
      this.organizationService.createStripe("").subscribe(data => {
        window.location.href = data
      }, error => {
        this.isCallingInscription = false;
      });
    }
  }

  cancelInscriptionStripe() {
    this.isCallingInscription = true;
    this.organizationService.cancelStripe().subscribe(data => {
      window.location.href = data
    },error => {
      this.isCallingInscription = false;
    })
  }

  inscriptionDev() {
    let value: Organization = {};
    value.email = this?.developpeurFormulaire?.get("email")?.value;
    value.name = this?.developpeurFormulaire?.get("organisationName")?.value;
    this.isCallingInscription = true;
    this.organizationService.createPartner(value).subscribe(data => {
      window.location.href = ""
    },error => {
      this.isCallingInscription = false;
    })
  }

  complete() {

    if (this.developpeurFormulaire.valid) {
      return true
    }
    return false;
  }
  checkCountry() {
    const result = this.countries.filter(option => option.nameEn.toLowerCase().match(this.selectCountryForm.get("countryCode")?.value.toLowerCase()));
    if (!result.length || result.length > 1) {
      this.selectCountryForm.controls['countryCode'].setErrors({'noCountry': true});
    } else {
      this.countryCodeSelected = result[0];
    }
  }

  presentation: boolean = environment.presentation;

}
