import { Component, OnInit } from '@angular/core';
import { CookieService } from "../cookie.service";

interface CookieOption {
  name: string;
  description: string;
  accepted: boolean;
  readOnly: boolean;
}

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

  cookiesAccepted: boolean;
  showOptions: boolean = false;
  cookiesList: CookieOption[] = [
    { name: 'cookie.necessaire', description: 'cookie.necessaireDesc', accepted: true, readOnly: true },
    { name: 'cookie.analtiques', description: 'cookie.analtiquesDesc', accepted: true, readOnly: false },
  ];

  constructor(private cookieService: CookieService) { }

  ngOnInit(): void {
    //this.cookiesAccepted = this.cookieService.getCookie('consent') === 'true';
    this.getCookiesPreferences();
    this.cookieService.showOptions$.subscribe(show => {
      this.getCookiesPreferences();
      this.showOptions = show;
    });
  }

  getCookiesPreferences(): void {
    const storedPreferences = localStorage.getItem('cookiePreferences');
    if (storedPreferences) {
      const storedCookiesList: CookieOption[] = JSON.parse(storedPreferences);
      this.cookiesList = this.cookiesList.map(defaultCookie => {
        const storedCookie = storedCookiesList.find(cookie => cookie.name === defaultCookie.name);
        return storedCookie ? { ...defaultCookie, accepted: storedCookie.accepted } : defaultCookie;
      });
    }
    this.cookiesAccepted = storedPreferences !== null;
  }

  giveConsent(): void {
    this.cookieService.setCookieWithName('consent', 'true', 365);
    this.cookiesAccepted = true;
  }

  savePreferences(): void {
    localStorage.setItem('cookiePreferences', JSON.stringify(this.cookiesList));
    this.cookiesAccepted = true;
    this.showOptions = false;
    this.giveConsent();
  }

  acceptAll(): void {
    this.cookiesList.forEach(cookie => {
      if (!cookie.readOnly) {
        cookie.accepted = true;
      }
    });
    this.savePreferences();
  }

  declineAll(): void {
    this.cookiesList.forEach(cookie => {
      if (!cookie.readOnly) {
        cookie.accepted = false;
      }
    });
    this.savePreferences();
  }

  openOptions(): void {
    this.showOptions = true;
  }

  closeOptions(): void {
    this.showOptions = false;
  }
}
