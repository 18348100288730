<div class="wrapper">
  <div class="filtres-btn">
    <div fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="start center">
      <h1>{{"general.filtres" | translate}}</h1>
      <label class="reset-btn" (click)="resetFilters()">{{"general.réinitialiser" | translate}}</label>
    </div>
  </div>
  <div class="filtre-liste">
    <mat-checkbox (change)="updateFilters()" [(ngModel)]="filterNew">New</mat-checkbox>
    <mat-checkbox (change)="updateFilters()" [(ngModel)]="filterLeavingSoon">Leaving soon</mat-checkbox>
    <mat-checkbox (change)="updateFilters()" [(ngModel)]="filterSolo">Solo</mat-checkbox>
    <mat-checkbox (change)="updateFilters()" [(ngModel)]="filterMultiplayer">Multiplayer</mat-checkbox>
    <h2>Genres</h2>

      <div *ngFor="let genre of gameGenres">
        <mat-checkbox *ngIf="expanded || genre.isPopular || genre.checked" (change)="updateFilters()" [(ngModel)]="genre.checked">{{ genre.name! | filtreName }}</mat-checkbox>
      </div>
    <button *ngIf="false" color="primary" mat-icon-button (click)="toggleExpansion()">
      <mat-icon *ngIf="!expanded">expand_circle_down</mat-icon>
    </button>
    <button mat-icon-button (click)="toggleExpansion()">
      <mat-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
  </div>
</div>
