import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {GameService} from "../../swagger/api/game.service";
import {OrganizationService} from "../../swagger/api/organization.service";

@Component({
  selector: 'app-game-manager',
  templateUrl: './game-manager.component.html',
  styleUrls: ['./game-manager.component.scss']
})
export class GameManagerComponent implements OnInit {
  public selectedIndex: number = 0;
  public gameName: string = "";
public idGame: number;
public idOrg: number;
public canEdit: boolean = true;
  constructor(private router: Router, private route: ActivatedRoute, private gameService: OrganizationService) {
    this.route.params.subscribe(params => {
      this.idGame = +params['idGame'];
      this.idOrg = +params['idOrganization'];
      this.gameService.getGamesByIdForPartner(this.idOrg,this.idGame).subscribe(game => {
        if (game.title) {
          this.gameName = game.title
        }
      });
    });
  }

  ngOnInit(): void {



    if (this.route.firstChild) {
      this.route.firstChild.url.subscribe(urlPath => {
        this.setActiveTabBasedOnRoute(urlPath[0].path);
      });
    } else {
      this.router.navigate(['./game'], {relativeTo: this.route});
    }


  }

  setActiveTabBasedOnRoute(path: string) {
    if (path.startsWith('game')) {
      this.selectedIndex = 0; // Index de l'onglet "Mon organisation"
    } else if (path.startsWith('pages')) {
      this.selectedIndex = 1; // Index de l'onglet "Mon organisation"
    } else if (path === 'development') {
      this.selectedIndex = 2; // Index de l'onglet "Jeux"
    } else if (path === 'achievements') {
      this.selectedIndex = 3; // Index de l'onglet "Jeux"
    } else if (path === 'team') {
      this.selectedIndex = 4; // Index de l'onglet "Jeux"
    }
  }

  onTabChange(index: number) {
    switch (index) {
      case 0:
        this.router.navigate(['./game'], {relativeTo: this.route});
        break;
      case 1:
        this.router.navigate(['./pages'], {relativeTo: this.route});
        break;
      case 2:
        this.router.navigate(['./development'], {relativeTo: this.route});
        break;
      case 3:
        this.router.navigate(['./achievements'], {relativeTo: this.route});
        break;
      case 4:
        this.router.navigate(['./team'], {relativeTo: this.route});
        break;
    }
  }

}
