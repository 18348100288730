<div fxLayoutGap="20px" class="main-wrapper white-theme " fxLayout="row" fxLayoutAlign="center none">

  <mat-selection-list [disabled]="uploadProgress>0" (selectionChange)="changeView()" #gameReleasesSelect
                      [multiple]="false">
    <mat-list-option [value]="{showKey:true}" *ngIf="game?.publicKey">
      {{ "dev.obtenirCléPublique" | translate }}
    </mat-list-option>
    <mat-list-option *ngIf="!gameReleases[0] || gameReleases[0].state == 'exploited' " class="dev">
      {{ "dev.nouvelleRelease" | translate }}
    </mat-list-option>
    <mat-list-option *ngFor="let gameRelease of gameReleases" [value]="gameRelease">
      {{ gameRelease.major }}.{{ gameRelease.minor }}.{{ gameRelease.patch }}
    </mat-list-option>
  </mat-selection-list>


  <div class="ip-max-width" *ngIf="  !isLoading">
    <div class="ip-area">
      <div *ngIf="showWhat===0">
        <h1> {{ "dev.nouvelleRelease" | translate }}
        </h1>
        <div>
          <div class="pre-line justify">
          {{ "dev.buildExplication" | translate }}
          </div>
          <form>
            <div class="version">
              <mat-form-field>
                <mat-label>Major</mat-label>
                <input matInput [formControl]="major" placeholder="0" (input)="validateAndCorrect(major,true)">
              </mat-form-field>
              <span>.</span>
              <mat-form-field>
                <mat-label>Minor</mat-label>
                <input matInput [formControl]="minor" placeholder="0" (input)="validateAndCorrect(minor,false)">
              </mat-form-field>
              <span>.</span>
              <mat-form-field>
                <mat-label>Patch</mat-label>
                <input matInput [formControl]="patch" placeholder="0" (input)="validateAndCorrect(patch,false)">
              </mat-form-field>
            </div>
          </form>


          <button mat-flat-button color="primary" (click)="uploadBuildInput.click()" type="button">
            {{ "dev.uploaderUnBuild" | translate }}            <input style="display:none;" #uploadBuildInput
                                                                      (change)="requestUpload($event)"
                                                                      (click)="resetFileInput(uploadBuildInput)"
                                                                      type="file"
                                                                      name="image" accept="application/zip"/>
          </button>
          <div fxLayout="column" fxLayoutAlign="center center" *ngIf="uploadProgress >= 0.1">
            <div>
              {{ uploadProgress }} %
            </div>
            <mat-spinner [value]="uploadProgress" [mode]="'determinate'"></mat-spinner>
          </div>
        </div>
      </div>

      <div *ngIf="showWhat===1">
        <h1>{{ "dev.release" | translate }}
          {{ gameReleasesSelected.major }}.{{ gameReleasesSelected.minor }}.{{ gameReleasesSelected.patch }}</h1>
        <div>
          {{ "dev.uploadéLe" | translate }} <b>{{ gameReleasesSelected.dateUpload | date:'dd/MM/yyyy' }}</b>
          {{ "general.à" | translate }}
          <b>{{ gameReleasesSelected.dateUpload | date:'HH:mm' }}</b> {{ "general.par" | translate }}
          <b>{{ gameReleasesSelected.uploadedBy }}</b>.
          <br>
          <div *ngIf="gameReleasesSelected?.state != GameRelease.StateEnum.Fileexe" fxLayout="row" fxLayoutAlign="center none"> {{ "dev.leStatutDeLaReleaseEst" | translate }} :
            <b *ngIf="gameReleasesSelected?.state"> {{ gameReleasesSelected?.state! | translate}}</b>
            <mat-spinner
              *ngIf="gameReleasesSelected.state === GameRelease.StateEnum.Waiting ||
              gameReleasesSelected.state === GameRelease.StateEnum.Uploading||
              gameReleasesSelected.state === GameRelease.StateEnum.Inprogress"
              diameter="20"></mat-spinner>
          </div>
          <div class="message-warning" *ngIf="gameReleasesSelected.state == GameRelease.StateEnum.Fileexe">
            {{ "dev.aucunFichierExeDetecte" | translate}}
          </div>
        </div>


        <br>
        <div *ngIf="gameReleasesSelected?.state === 'available'" class="pre-line justify">
          {{ "dev.selectPathExplication" | translate }}
        </div>
        <mat-select class="selection-path" *ngIf="gameReleasesSelected?.state === 'available'" [(value)]="idPath"
                    [placeholder]="'dev.selectionnezUnPath' | translate">
          <mat-option *ngFor="let path of gameReleasesSelected.paths" [value]="path.id">
            {{ path.path }}
          </mat-option>
        </mat-select>
        <div *ngIf="gameReleasesSelected.state !== 'exploited' &&
         gameReleasesSelected.state !== 'inprogress' && gameReleasesSelected.state !== 'deleted' && gameReleasesSelected.state !== 'history'"
             fxLayoutGap="20px"
             fxLayout="row" fxLayoutAlign="start  center">
          <button (click)="cancelRelease(gameReleasesSelected)" mat-button color="warn" type="button"
                  mat-raised-button>{{ "general.annuler" | translate }}
          </button>

          <button *ngIf="gameReleasesSelected?.state === 'available'" [disabled]="!idPath"
                  (click)="goLive(gameReleasesSelected)"
                  mat-flat-button
                  color="primary" type="button"
                  mat-raised-button>{{ "dev.publierEnBeta" | translate }}
          </button>

          <button *ngIf="gameReleasesSelected?.state === 'beta'"
                  (click)="goLive(gameReleasesSelected)"
                  mat-flat-button
                  color="primary" type="button"
                  mat-raised-button>{{ "dev.publierEnProduction" | translate }}
          </button>
          <mat-icon *ngIf="gameReleasesSelected?.state === 'available'" #tooltip="matTooltip" (click)="tooltip.toggle()"  [matTooltipPosition]="'right'" [matTooltip]="'helper.beta' | translate">help</mat-icon>
          <mat-icon *ngIf="gameReleasesSelected?.state === 'beta'" #tooltip="matTooltip" (click)="tooltip.toggle()"  [matTooltipPosition]="'right'" [matTooltip]="'helper.proudction' | translate">help</mat-icon>

        </div>

      </div>
      <div *ngIf="showWhat===2">
        <h1>{{ "dev.cléPublique" | translate }} </h1>
        <div class="pre-line justify">
          {{ "dev.cléPubliqueExplication" | translate }}
        </div>
        <p class="public-key">
          <mat-form-field appearance="fill">
            <mat-label>{{ "dev.votreCléPublique" | translate }}</mat-label>
            <input [value]="game?.publicKey" [readonly]="true" #inputKey id="pubKey" matInput
                   [type]="hide ? 'password' : 'text'">
            <button type="button" mat-icon-button matSuffix (click)="toggleVisibility(inputKey)"
                    [attr.aria-label]="'Toggle visibility'" [attr.aria-pressed]="hide">
              <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
          </mat-form-field>
        </p>

      </div>


    </div>

  </div>


</div>
